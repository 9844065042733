import React from "react"

import SEO from "../components/seo"

import { ThemeProvider } from "styled-components"
import { theme } from "../styles"

import { GlobalStyle } from "../styles"
import Layout from "src/components/Layout"

const NotFoundPage = () => (
  <ThemeProvider theme={theme}>
    <Layout theme={theme} withBreadCrumb activePage="NotFound">
      <GlobalStyle bodyMarginTop="0vw" />
      <SEO title="404: Not found" />
      <h1 style={{ fontFamily: theme.subTitleFont }}>
        You just hit a route that doesn&#39;t exist...
      </h1>
    </Layout>
  </ThemeProvider>
)

export default NotFoundPage
